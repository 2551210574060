import React from 'react';
import Feedback from '../components/Feedback';

const ChestionarePage = () => (
  <section>
    <Feedback />
  </section>
);

export default ChestionarePage;
