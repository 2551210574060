import React from 'react';
import { StaticQuery, graphql } from 'gatsby';

import Title from './Title';

const Feedback = () => (
  <StaticQuery
    query={graphql`
      query feedbackQuery {
        allDataJson {
          edges {
            node {
              feedback {
                title
                description
                link
              }
            }
          }
        }
      }
    `}
    render={data => {
      const { feedback } = data.allDataJson.edges[0].node;
      return (
        <section>
          <Title title="Chestionare" />
          <h2>Chestionare</h2>
          {feedback.map(({ title, description, link }) => (
            <div className="article" key={link}>
              <h3>{title}</h3>
              <p>{description}</p>
              <a
                href={link}
                alt={`legătura chestionar ${title}`}
                target="_blank"
                className="button"
              >
                Accesează
              </a>
            </div>
          ))}
        </section>
      );
    }}
  />
);

export default Feedback;
